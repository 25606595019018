import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
// import CaseStudyArea from '../containers/global/case-study-area'
import PortfolioArea from '../containers/global/portfolio-area'
import CTAArea from '../containers/global/cta-area/section-one'

const PortfolioPage = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO title="Our Portfolio"/>
    <Header/>
    <PageHeader 
        pageContext={pageContext} 
        location={location}
        title="Our Portfolio"
    />
    <main className="site-wrapper-reveal">
        <PortfolioArea/>
        <CTAArea/>
    </main>
    <Footer/>
  </Layout>
)
 
export default PortfolioPage
 